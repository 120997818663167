import React, { useState, useEffect } from "react";
import { currentAuthenticatedUser } from "../../services/Auth";
import { SSO_PREFIX, isSSO } from "../../config";

export const getUsername = (username: string) => {
  if (isSSO && typeof username == "string") {
    username = username.split(SSO_PREFIX).join("").split("@")[0];
  }
  return username;
};

export const createSsoUsername = email => {
  return SSO_PREFIX.concat(email);
};

const CurrentUser = () => {
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const setUsernameBasedOnCurrentUser = async () => {
      const user = await currentAuthenticatedUser();
      if (user) {
        setUsername(getUsername(user.username));
      }
    };
    setUsernameBasedOnCurrentUser();
  }, []);
  return username;
};
export default CurrentUser;
