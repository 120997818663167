/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { currentAuthenticatedUser } from "../services/Auth";
import { TENANT, TAG, VERSION } from "../config";
import { getUsername } from "../components/CurrentUser/CurrentUser";

const pendo = window.pendo;

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  };

  componentDidMount() {
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      currentAuthenticatedUser().catch(() => {
        if (this.state.isAuthenticated)
          this.setState({ isAuthenticated: false });
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  authenticate() {
    currentAuthenticatedUser()
      .then(user => {
        this._initializePendo(user);
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(() => this.setState({ loaded: true, isAuthenticated: false }));
  }

  render() {
    const { children, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;

    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: location }
              }}
            />
          );
        }}
      />
    );
  }

  _initializePendo(user) {
    let visitor = {
      id: getUsername(user.username), // Required if user is logged in
      email: user.attributes.email
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    };

    pendo.initialize({
      visitor: visitor,
      account: {
        id: TENANT,
        version: VERSION,
        dockerTag: TAG
        // name:         // Optional
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }
}

PrivateRoute = withRouter(PrivateRoute);

export default PrivateRoute;
