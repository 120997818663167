import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import Badge from "@mui/material/Badge";
import * as Sentry from "@sentry/browser";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../../../graphql/queries";

import {
  Container,
  Contents,
  StyledAvatar,
  MainContent,
  Footer
} from "./StyledElements";
import LinkButton from "../../LinkButton";
import NotificationActionsMenu from "../NotificationActionsMenu";
import { isSSO } from "../../../config";
import { getUsername } from "../../CurrentUser/CurrentUser";

const TaskNotification = ({ notification }) => {
  return (
    <Container read={notification.read}>
      <Badge
        color="error"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        variant="dot"
        invisible={notification.read}
      >
        <StyledAvatar color="#0a6fa6">T</StyledAvatar>
      </Badge>
      <Contents>
        <MainContent>
          <Message notification={notification} />
        </MainContent>
        <Footer>
          <Typography variant="caption">
            {dayjs(notification.created_date).format("MM/DD/YYYY")} at{" "}
            {dayjs(notification.created_date).format("h:mm a")}
          </Typography>
        </Footer>
      </Contents>
      <NotificationActionsMenu notification={notification} />
    </Container>
  );
};
export default TaskNotification;
TaskNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    read: PropTypes.bool.isRequired,
    created_date: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    task: PropTypes.object
  })
};

/**
 * Display the correct message depending on type  of notification
 */
const Message = ({ notification }) => {
  const truncateString = string => {
    return string.length > 40 ? string.slice(0, 40).concat("...") : string;
  };
  const history = useHistory();
  const navigateToTaskPanel = shortage => {
    const route = `/shortages?shortage=${shortage.id}&selectedPanel=tasks`;
    history.push(route);
  };
  const activeShortage = !notification.task.shortageListing.archived_date;

  if (activeShortage) {
    switch (notification.key) {
      case "ASSIGNED":
        return (
          <AssignedMessage
            notification={notification}
            navigateToTaskPanel={navigateToTaskPanel}
            truncateString={truncateString}
          />
        );
      case "COMPLETED":
        return (
          <CompletionMessage
            notification={notification}
            navigateToTaskPanel={navigateToTaskPanel}
            truncateString={truncateString}
          />
        );
      case "OVERDUE":
        return (
          <OverdueMessage
            notification={notification}
            navigateToTaskPanel={navigateToTaskPanel}
            truncateString={truncateString}
          />
        );
      default:
        Sentry.captureMessage(
          `unknown key for TaskNotification ${notification.key}`
        );
        return null;
    }
  } else {
    return <ArchivedShortageMessage notification={notification} />;
  }
};

const AssignedMessage = ({
  notification,
  navigateToTaskPanel,
  truncateString
}) => {
  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Task: </b>You&apos;ve been assigned a new task re:{" "}
        <LinkButton
          onClick={() => navigateToTaskPanel(notification.task.shortageListing)}
          text={`${truncateString(
            notification.task.shortageListing.primary_product.description
          )}.`}
        />
      </Typography>
    </div>
  );
};

const CompletionMessage = ({ notification, navigateToTaskPanel }) => {
  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Task: </b>A task that you&apos;ve created has been completed re:{" "}
        <LinkButton
          onClick={() => navigateToTaskPanel(notification.task.shortageListing)}
          text={`${notification.task.shortageListing.primary_product.description}.`}
        />
      </Typography>
    </div>
  );
};

const OverdueMessage = ({ notification, navigateToTaskPanel }) => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER);
  if (loading) return null;
  if (error) return null;
  if (!data) return null;

  const { getCurrentUser: user } = data;
  const username = getUsername(user.username);
  const taskRelationship =
    username === getUsername(notification.task.createdBy)
      ? "you've created"
      : "you've been assigned";

  return (
    <div>
      <Typography
        display="inline"
        style={{ fontSize: ".875rem", lineHeight: "1.2" }}
      >
        <b>Task: </b>A task {taskRelationship} is overdue re:{" "}
        <LinkButton
          onClick={() => navigateToTaskPanel(notification.task.shortageListing)}
          text={`${notification.task.shortageListing.primary_product.description}.`}
        />
      </Typography>
    </div>
  );
};

const ArchivedShortageMessage = ({ notification }) => (
  <Typography
    display="inline"
    style={{ fontSize: ".875rem", lineHeight: "1.2" }}
  >
    <b>Task: </b> Task update re:{" "}
    {notification.task.shortageListing.display_name} (CIN:{" "}
    {notification.task.shortageListing.primary_product.external_id}){" "}
    <i>(Archived Shortage)</i>
  </Typography>
);
